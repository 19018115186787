import React, { useState } from 'react';
import {
  Container, Typography, Box, Grid, Paper, Button, Modal, TextField, FormControl, InputLabel, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import axios from 'axios';  // Importando o axios para fazer as requisições
import { API } from '../../Services/api';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#E50914',
          color: 'white',
          '&:hover': {
            backgroundColor: '#C40812',
          },
          fontWeight: 700,
          padding: '12px',
          fontSize: '1.1rem',
          borderRadius: '5px'
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
        },
      },
    },
  },
});

const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#2a95b6',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#2a95b6',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#2a95b6',
    },
    '&:hover fieldset': {
      borderColor: '#2a95b6',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2a95b6',
    },
  },
});

const Admission = () => {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({
    nomeResponsavel: '',
    celular: '',
    email: '',
    nomeAluno: '',
    serieAluno: '',
    sede: '',
    dataExame: '',
  });

  const [messageModal, setMessageModal] = useState({ open: false, success: false, message: '' });

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleCloseMessageModal = () => setMessageModal({ open: false, success: false, message: '' });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Função para enviar o formulário para o back-end
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('FormData:', formData);

    try {
      const response = await API.post('/admissions', formData); // URL relativa com baseURL
      console.log('Formulário enviado com sucesso:', response.data);
      handleCloseModal();
      setMessageModal({ open: true, success: true, message: 'Formulário enviado com sucesso!' });
    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
      setMessageModal({ open: true, success: false, message: 'Erro ao enviar o formulário. Tente novamente mais tarde.' });
    }
  };

  const roteiros = [
    { id: 1, titulo: 'Roteiro 1', descricao: 'Roteiro para o 2° ano', link: 'https://manueldasilva.s3.us-west-2.amazonaws.com/uploads/activities/Processo+de+Admiss%C3%A3o+2025+-+2%C2%BA+Ano+EF1.pdf' },
    { id: 2, titulo: 'Roteiro 2', descricao: 'Roteiro para o 3° ano', link: 'https://manueldasilva.s3.us-west-2.amazonaws.com/uploads/activities/Processo+de+Admiss%C3%A3o+2025+-+3%C2%BA+Ano+EF1.pdf' },
    { id: 3, titulo: 'Roteiro 3', descricao: 'Roteiro para o 4° ano', link: 'https://manueldasilva.s3.us-west-2.amazonaws.com/uploads/activities/Processo+de+Admiss%C3%A3o+2025+-+4%C2%BA+Ano+EF1.pdf' },
    { id: 3, titulo: 'Roteiro 4', descricao: 'Roteiro para o 5° ano', link: 'https://manueldasilva.s3.us-west-2.amazonaws.com/uploads/activities/Processo+de+Admiss%C3%A3o+2025+-+5%C2%BA+Ano+EF1.pdf' },
    { id: 3, titulo: 'Roteiro 5', descricao: 'Roteiro para o 6° ano', link: 'https://manueldasilva.s3.us-west-2.amazonaws.com/uploads/activities/Processo+de+Admiss%C3%A3o+2025+-+6%C2%BA+Ano+EF2.pdf' },
    { id: 3, titulo: 'Roteiro 6', descricao: 'Roteiro para o 7° ano', link: 'https://manueldasilva.s3.us-west-2.amazonaws.com/uploads/activities/Processo+de+Admiss%C3%A3o+2025+-+7%C2%BA+Ano+EF2.pdf' },
    { id: 3, titulo: 'Roteiro 7', descricao: 'Roteiro para o 8° ano', link: 'https://manueldasilva.s3.us-west-2.amazonaws.com/uploads/activities/Processo+de+Admiss%C3%A3o+2025+-+8%C2%BA+Ano+EF2.pdf' },
    { id: 3, titulo: 'Roteiro 8', descricao: 'Roteiro para o 9° ano', link: 'https://manueldasilva.s3.us-west-2.amazonaws.com/uploads/activities/Processo+de+Admiss%C3%A3o+2025+-+9%C2%BA+Ano+EF2.pdf' },
    // Adicione mais roteiros conforme necessário
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container sx={{ pt: { xs: 5, md: 10 }, pb: { xs: 70, md: 80 } }}>
        <Box mb={6} mt={2} textAlign="center">
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{ fontSize: { xs: '2rem', md: '2.5rem' }, fontWeight: 700, color: '#2a95b6' }}
          >
            Processo de Admissão
          </Typography>
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: { xs: 3, md: 5 }, mb: { xs: 3, md: 5 }, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', height: '100%' }}>
              <Typography variant="h4" component="h2" gutterBottom sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
                Inscrição
              </Typography>
              <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'justify', mb: 4 }}>
                Estimados Pais ou Responsáveis,

                Sejam bem-vindos ao nosso Colégio! Somos uma instituição de ensino privado com 43 anos de experiência, dedicada à educação de crianças e jovens.

                <Box component="span" sx={{ display: 'block', mt: 2, mb: 1, fontWeight: 'bold' }}>
                  Nossa Missão
                </Box>
                Oferecer um ambiente de convivência que promove resultados satisfatórios e a formação integral dos alunos, utilizando talentos humanos e tecnologias avançadas.

                <Box component="span" sx={{ display: 'block', mt: 3, mb: 1, fontWeight: 'bold' }}>
                  Pontos Importantes sobre o Teste de Sondagem:
                </Box>
                <ol style={{ marginLeft: '20px' }}>
                  <li>
                    Para alunos da Educação Infantil e 1º Ano, a sondagem é realizada por meio de entrevista com o setor de Psicologia ou Pedagógico do Colégio, na sede Álvaro Weyne. Entre em contato pelo WhatsApp: <strong>85 98766-0739</strong>.
                  </li>
                  <li>
                    Alunos do Ensino Fundamental, do 2º ao 9º Ano, realizarão o teste de sondagem nas áreas de Linguagens e Matemática, na sede Álvaro Weyne.
                  </li>
                  <li>
                    Para alunos do Ensino Médio, a sondagem é feita através de entrevista com o setor de Psicologia ou Pedagógico do Colégio, na sede Carlito Pamplona. Entre em contato pelo WhatsApp: <strong>85 9 8902-3108</strong>.
                  </li>
                </ol>

                Para realizar a inscrição no teste de sondagem do 2° ao 9° Ano, é necessário preencher a ficha de inscrição clicando no link abaixo.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                sx={{ mt: 2, fontSize: { xs: '0.9rem', md: '1rem' }, textTransform: 'none' }}
              >
                Preencher Formulário
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: { xs: 3, md: 5 }, mb: { xs: 3, md: 5 }, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', height: '100%' }}>
              <Typography variant="h4" component="h2" gutterBottom sx={{ fontSize: { xs: '1.5rem', md: '2rem' } }}>
                Visita Guiada
              </Typography>
              <Typography variant="body1" component="p" gutterBottom>
                Convidamos você e sua família para uma visita guiada em nossa escola.
              </Typography>
              <Button
                variant="contained"
                href="https://wa.me/558587660739"
                sx={{
                  backgroundColor: '#4CAF50',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#45a049',
                  },
                  mt: 2,
                  fontSize: { xs: '0.9rem', md: '1rem' },
                  textTransform: 'none'
                }}
                startIcon={<WhatsAppIcon />}
              >
                Fale Conosco no WhatsApp
              </Button>
            </Paper>
          </Grid>
        </Grid>

        {/* Modal para o formulário */}
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box sx={{ width: 500, bgcolor: 'white', p: 4, margin: 'auto', mt: 10, borderRadius: 2, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h6" gutterBottom>
              Formulário de Inscrição
            </Typography>
            <form onSubmit={handleSubmit}>
              <CustomTextField
                label="Nome do Responsável"
                name="nomeResponsavel"
                fullWidth
                required
                value={formData.nomeResponsavel}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <CustomTextField
                label="Celular"
                name="celular"
                fullWidth
                required
                value={formData.celular}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <CustomTextField
                label="E-mail"
                name="email"
                fullWidth
                required
                value={formData.email}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <CustomTextField
                label="Nome do Aluno"
                name="nomeAluno"
                fullWidth
                required
                value={formData.nomeAluno}
                onChange={handleInputChange}
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Série do Aluno</InputLabel>
                <Select
                  name="serieAluno"
                  value={formData.serieAluno}
                  onChange={handleInputChange}
                  label="Série do Aluno"
                >
                  <MenuItem value="2° Ano">2° Ano</MenuItem>
                  <MenuItem value="3° Ano">3° Ano</MenuItem>
                  <MenuItem value="5° Ano">5° Ano</MenuItem>
                  <MenuItem value="6° Ano">6° Ano</MenuItem>
                  <MenuItem value="7° Ano">7° Ano</MenuItem>
                  <MenuItem value="8° Ano">8° Ano</MenuItem>
                  <MenuItem value="9° Ano">9° Ano</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Data do Teste de Sondagem</InputLabel>
                <Select
                  name="dataExame"
                  value={formData.dataExame}
                  onChange={handleInputChange}
                  label="Data do Exame"
                >
                  {/* <MenuItem value="28/09">28/09</MenuItem> */}
                  <MenuItem value="19/10">19/10</MenuItem>
                </Select>
              </FormControl>
              <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
                Enviar
              </Button>
            </form>
          </Box>
        </Modal>

        {/* Modal para mensagem de sucesso ou erro */}
        <Modal open={messageModal.open} onClose={handleCloseMessageModal}>
          <Box
            sx={{
              width: 400,
              bgcolor: 'white',
              p: 4,
              margin: 'auto',
              mt: 10,
              borderRadius: 2,
              boxShadow: '0 4px 8px rgba(221, 19, 19, 0.1)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',  // Alinha horizontalmente
              alignItems: 'center',      // Alinha verticalmente
              textAlign: 'center'        // Alinha o texto
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{ color: messageModal.success ? 'green' : 'red' }}
            >
              {messageModal.success ? 'Sucesso' : 'Erro'}
            </Typography>
            <Typography variant="body1" sx={{ color: 'black' }}>
              {messageModal.message}
            </Typography>
            <Button onClick={handleCloseMessageModal} variant="contained" sx={{ mt: 2 }}>
              Fechar
            </Button>
          </Box>
        </Modal>

        {/* Nova seção de Roteiros */}
        <Box mt={5}>
          <Typography variant="h4" gutterBottom>
            Roteiros de Estudo
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Título</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Ação</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roteiros.map((roteiro) => (
                  <TableRow key={roteiro.id}>
                    <TableCell>{roteiro.titulo}</TableCell>
                    <TableCell>{roteiro.descricao}</TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        href={roteiro.link}
                        target="_blank"
                        sx={{ textTransform: 'none' }}
                      >
                        Baixar PDF
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Admission;
